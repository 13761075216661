<template>
  <div class="subscription-search">
    <div class="subscription-search--searchfield">
      <telia-p>{{ t("hardwareSubscriptionConfigurator.SUBSCRIPTION_SEARCH") }}</telia-p>
      <telia-text-input
        data-testid="number-search-input"
        type="text"
        @input="handleSearchChange"
        :placeholder="t('hardwareSubscriptionConfigurator.SUBSCRIPTION_SEARCH_PLACEHOLDER')"
      />
    </div>
    <div v-if="searching" class="skeleton-loader" data-testid="search-result-skeletons">
      <telia-skeleton />
      <telia-skeleton />
      <telia-skeleton />
    </div>

    <ul class="subscription-search__result" v-else>
      <li
        v-for="item in subscriptionsWithValidity"
        :key="item.id"
        :class="`subscription-search__result-item ${
          canExtendCommitment(item) ? '' : 'subscription-search__result-item__disabled'
        }`"
        @click.prevent="handleSelectSubscription(item)"
      >
        <img
          src="https://www.telia.se/dam/jcr:0139a980-77e5-4fa6-a708-a70f73846c90/%20single-sim-2021.png"
          class="subscription-search__result--image"
          :alt="item.user"
          draggable="false"
        />
        <div class="subscription-search__result--item-info">
          <telia-p t-id="extend-existing-subscription__search-result" v-html="mainMatch(item)">
          </telia-p>
          <div class="subscription-search__result--additional-items">
            <div
              class="subscription-search__result--additional-item"
              v-for="match in additionalMatches(item)"
              :key="match.key"
            >
              <telia-p class="subscription-search__result--additional-item__label">{{
                match.key
              }}</telia-p>
              <telia-p v-html="match.value"></telia-p>
            </div>
          </div>
          <div v-if="!canExtendCommitment(item)">
            <div v-if="isNumberInBasket(item.phoneNumber)" class="tooltip-container">
              <telia-p>{{
                t("hardwareSubscriptionConfigurator.COMMITMENT_NOT_EXTENDABLE_ALREADY_IN_BASKET")
              }}</telia-p>
            </div>
            <div v-else-if="!item.valid">
              <telia-p>{{
                t("hardwareSubscriptionConfigurator.NUMBER_TO_BE_DISCONNECTED")
              }}</telia-p>
            </div>
            <div v-else class="tooltip-container">
              <telia-p>{{
                t("hardwareSubscriptionConfigurator.COMMITMENT_NOT_EXTENDABLE", {
                  endDate: item.commitment.endDate,
                })
              }}</telia-p>
              <!-- <tooltip :content="t('EXTEND_COMMITMENT_TOOLTIP')"> </tooltip> -->
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="search-subscription--notifications">
      <telia-notification
        heading-tag="h3"
        status="information"
        t-id="extend-existing-subscription__noSubscriptionsFound"
        v-if="searchEmpty && !searching && !errorText && !fetchingBasket"
      >
        <span slot="heading">
          {{ t("hardwareSubscriptionConfigurator.NO_SUBSCRIPTION_HEADER") }}
        </span>
        <span slot="content">
          <telia-p>
            {{ t("hardwareSubscriptionConfigurator.NO_SUBSCRIPTIONS_FOUND_INFORMATION") }}
          </telia-p>
        </span>
      </telia-notification>
      <telia-notification
        heading-tag="h3"
        status="warning"
        t-id="extend-existing-subscription__hitLimitExceeded"
        v-if="hitLimitExceeded"
      >
        <span slot="heading">
          {{ t("hardwareSubscriptionConfigurator.HIT_LIMIT_EXCEEDED_HEADING") }}
        </span>
        <span slot="content">
          <telia-p>
            {{ t("hardwareSubscriptionConfigurator.HIT_LIMIT_EXCEEDED_BODY") }}
          </telia-p>
        </span>
      </telia-notification>
      <telia-notification
        heading-tag="h3"
        status="warning"
        t-id="extend-existing-subscription__errorMessage"
        v-if="errorText"
      >
        <span slot="heading">{{ t("hardwareSubscriptionConfigurator.GENERAL_ERROR_HEADER") }}</span>
        <span slot="content">
          <telia-p>
            {{ t(errorText) }}
          </telia-p>
        </span>
      </telia-notification>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SubscriptionItem } from "./extendSubscriptionTypes";
import { computed, onMounted, ref } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import moment from "moment";
import { getBasket } from "../../../../../service/BasketService";
import {
  searchSubscriptionsInScope,
  validateForExtension,
} from "../../../subscription-configurator/shared/services/susbcription-search-service";
import { formatToMsisdn } from "@telia/b2x-phone-number-format";

interface Props {
  tscid: string;
  scopeId: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{ (event: "select-subscription", value: SubscriptionItem): void }>();

const subscriptions = ref<SubscriptionItem[]>([]);
const errorText = ref("");
const hitLimitExceeded = ref(false);
const searching = ref(false);
const searchString = ref("");
const fetchingBasket = ref(false);
const msisdnsInBasket = ref<string[]>([]);
const searchEmpty = ref(false);

const invalidForExtensionNumbers = ref<string[]>([]);
const validCheckError = ref(false);

onMounted(async () => {
  await getNumbersInBasket();
});

const subscriptionsWithValidity = computed((): (SubscriptionItem & { valid: boolean })[] => {
  return subscriptions.value.map((subscription) => ({
    ...subscription,
    valid: !invalidForExtensionNumbers.value.includes(subscription.phoneNumber),
  }));
});

async function handleSelectSubscription(item: SubscriptionItem) {
  const validity = await validateNumber(item.phoneNumber);

  if (canExtendCommitment(item) && validity) {
    emit("select-subscription", item);
  }
}

async function validateNumber(phoneNumber: string) {
  try {
    const validityResponse = await validateForExtension(props.scopeId, formatToMsisdn(phoneNumber));
    if (!validityResponse.valid) {
      invalidForExtensionNumbers.value = [...invalidForExtensionNumbers.value, phoneNumber];
    }
    return validityResponse.valid;
  } catch (error) {
    validCheckError.value = true;
    return false;
  }
}

function handleSearchChange(event: { target: { value: string } }) {
  searchString.value = event.target.value;
  searchSubscriptions(searchString.value);
}

async function getNumbersInBasket() {
  try {
    fetchingBasket.value = true;
    const basket = await getBasket(props.scopeId, props.tscid);
    msisdnsInBasket.value = basket.lines.map((line) => line.msisdn as string);
  } catch (error) {
    errorText.value = "SUBSCRIPTION_SEARCH_ERROR";
  } finally {
    fetchingBasket.value = false;
  }
}

async function searchSubscriptions(searchString: string) {
  if (searchString.length < 3) {
    return;
  }
  searching.value = true;
  errorText.value = "";
  hitLimitExceeded.value = false;
  searchEmpty.value = false;
  if (searchString !== "") {
    try {
      const searchResult = await searchSubscriptionsInScope(props.scopeId, searchString);
      searchResult.subscriptions.filter((result) => result.metadata.tscid === props.tscid);

      subscriptions.value = searchResult.subscriptions
        .filter((result) => result.metadata.tscid === props.tscid)
        .map(
          (result): SubscriptionItem => ({
            id: result.id,
            phoneNumber: result.title,
            icc: result.icc,
            user: result.user,
            reference: result.billingReference,
            commitment: {
              endDate: result.commitment.endDate ?? "",
            },
            components: result.components,
            description: result.description,
            subscriptionType: result.metadata.offeringnameofferingcode ?? "",
          })
        );
      if (subscriptions.value.length === 0) {
        searchEmpty.value = true;
      } else {
        searchEmpty.value = false;
      }
    } catch (error: any) {
      if (error?.json?.translationKey === "HIT_LIMIT_EXCEEDED") {
        hitLimitExceeded.value = true;
      } else {
        errorText.value = "SUBSCRIPTION_SEARCH_ERROR";
      }
    } finally {
      searching.value = false;
    }
  } else {
    subscriptions.value = [];
    searchEmpty.value = false;
    searching.value = false;
  }
}

function emboldMatchingCharacters(value: string): string {
  if (!value) {
    return "";
  }
  const embold = (value: string, rex: RegExp) =>
    value.replace(rex, (match) => `<strong>${match}</strong>`);

  const group = "[ -]*";
  const r = new RegExp(searchString.value.split("").join(group), "ig");
  return embold(value, r);
}
function mainMatch(item: SubscriptionItem) {
  return `${emboldMatchingCharacters(item.phoneNumber)}${
    item.user ? " / " + emboldMatchingCharacters(item.user) : ""
  }`;
}
function additionalMatches(item: SubscriptionItem) {
  let matches: Record<string, string>[] = [];

  const pushMatchIfValid = (key: string, value?: string) => {
    if (value?.includes(searchString.value)) {
      matches.push({ key: t(key), value: emboldMatchingCharacters(value) });
    }
  };
  if (searchString.value !== "") {
    pushMatchIfValid("DESCRIPTION", item.description);
    pushMatchIfValid("ICC", item.icc);
    if (item.components?.length > 0) {
      pushMatchIfValid("SUB_DESCRIPTION", item.components[0]?.description);
      pushMatchIfValid("SUB_ICC", item.components[0]?.icc);
    }
  }
  return matches;
}
function canExtendCommitment(item: SubscriptionItem) {
  return (
    (moment(item.commitment?.endDate)?.diff(moment(), "days") || 0) < 90 &&
    !isNumberInBasket(item.phoneNumber) &&
    item.valid
  );
}
function isNumberInBasket(number: string) {
  return !!msisdnsInBasket.value.find((msisdn) => msisdn === number.replace(/^0/, "46"));
}
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
.subscription-search {
  display: flex;
  flex-direction: column;

  &__result {
    list-style-type: none;
    padding-left: 1em;

    &-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: $telia-spacing-12;

      &__disabled {
        cursor: initial;
        opacity: 0.6;
      }
    }

    &--additional-items {
      display: flex;
      flex-flow: row wrap;
    }

    &--additional-item {
      display: flex;
      flex-direction: row;
      // font-size: 12px;
      // line-height: 22px;
      margin-right: 1.5rem;
      margin-top: 0.3rem;
      &__label {
        background: $telia-gray-200;
        border-radius: 4px;
        margin-right: 0.3rem;
        padding: 0 0.6rem;
      }
    }

    &--item-info {
      display: flex;
      flex-direction: column;
    }

    &--image {
      align-self: flex-start;
      max-height: 3.4rem;
      max-width: 3.7rem;
      margin-right: $telia-spacing-8;
    }
  }
}
.skeleton-loader {
  telia-skeleton {
    margin: $telia-spacing-8 0;
    width: 100%;
    height: 2rem;
  }
}
</style>
