<template>
  <div class="order-products" ref="element">
    <b2b-basket-wrapper
      v-if="!loadingOrganisations"
      t-id="order-products-container__b2b-basket-wrapper"
      :isOpen="basketOpen"
      :tscid="tscid"
      :isReplicatedMfa="stateStore.isReplicatedMfAgreement"
      @amanda-basket="($event: CustomEvent) => stateStore.setBasket($event.detail[0])"
      @spock-basket="($event: CustomEvent) => (spockBasket = $event.detail[0])"
      @update-drawer-state="$event = handleBasketDrawerState($event.detail[0])"
      @get-basket-error="handleBasketError"
    />
    <MaxItemsModal
      v-if="maxBasketItems"
      :is-open="showMaxItemsModal"
      :max-items-allowed="maxBasketItems"
      @close="handleCloseMaxItemsModal"
      @proceed-to-basket="handleOpenBasket"
    />
    <b2x-modal :is-open="showSurveyModal" @closeModal="handleCloseSurvey">
      <span slot="modal-body">
        <div id="mco_order_product_survey"></div>
      </span>
    </b2x-modal>
    <div class="order-products__configure-container" v-if="selectedProduct">
      <ProductConfiguratorWrapper
        v-if="selectedProduct && basket && !showConfirmation"
        :scopeId="scopeId"
        :tscid="tscid"
        :product="selectedProduct"
        :subscription-bundles="subscriptionBundles"
        :basket="basket"
        :canAddNewSubscription="isSubscriptionItemsAllowed"
        :isAddingItemToBasket="isAddingItemToBasket"
        :addToBasketError="addToBasketError"
        @add-to-basket="handleAddToBasket"
      />
      <ProductAddedConfirmation
        v-if="showConfirmation"
        :scopeId="scopeId"
        :tscid="tscid"
        :product="selectedProduct"
        :basket-count="numberOfItemsInBasket"
        :has-reached-max-number-of-items="hasReachedMaxNumberOfItems"
        :accessory-product-codes-in-basket="accessoryProductCodesInBasket"
        @update-basket="handleUpdateBasket"
        @open-basket="continueToBasket"
        @continue="closeConfiguration"
        @show-accessories="showAccessories"
      />
    </div>
    <div v-else>
      <b2b-layout
        :heading="t('orderHeader')"
        show-back-link
        :back-link-label="t('scopeSelector.backLink')"
        :back-link-href="`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`"
        :organisation-selector-disabled="true"
        show-organisation-selector
        has-tabs
        :selected-organisation="tscid"
      >
        <span slot="agreement-selector">
          <div class="order-products__customer-agreement-container">
            <CustomerSelector
              :selectedCustomerNumber="stateStore.customerNumber"
              :loading="loadingOrganisations || loadingCustomerNumbers"
              :customerNumberOptions="customerNumberOptions"
              :has-items-in-basket="numberOfItemsInBasket > 0"
              dark-background
              @handleCustomerChange="handleSelectCustomer"
            />
          </div>
        </span>
        <span slot="header">
          <telia-grid>
            <telia-row>
              <telia-col width="12" width-md="8" width-lg="8">
                <div>
                  <AgreementDownloads />
                </div>
              </telia-col>
              <telia-col width="12" width-md="4" width-lg="4">
                <div v-if="orderSurveyEnabled" class="survey-button">
                  <telia-button
                    t-id="show-survey-button"
                    variant="secondary-white"
                    size="sm"
                    :text="t('survey.link')"
                    @click="handleShowSurvey"
                  />
                </div>
              </telia-col>
            </telia-row>
          </telia-grid>
        </span>

        <div class="order-products__tab-categories">
          <telia-grid class="order-products__main">
            <Tabs
              v-if="basket"
              :active-tab="activeCategory"
              :general-errors="generalErrors"
              :category-errors="categoryErrors"
              :show-customer-number-notification="showCustomerNumberNotification"
              :permissions="permissions"
              @tab-change="tabChange"
            >
              <template #[Categories.Subscription]>
                <SubscriptionProductListing
                  :loading="fetching.subscriptions"
                  :tscid="tscid"
                  :subscriptionGroups="subscriptionBundles"
                  :nf-agreements="nfAgreements"
                  :subscriptionDisabled="isBlacklisted || !isSubscriptionItemsAllowed"
                  @selected-subscription="onSelectSubscription"
                />
              </template>
              <template #[Categories.Hardware]>
                <HardwareProductListing
                  :loading="fetching.hardware"
                  t-id="order-products-container__mobile-phone-product-listing"
                  :hardware-groups="hardwareBundles"
                  :hardware-disabled="isBlacklisted || !isHardwareItemsAllowed"
                  @selected-product="onSelectProduct"
                />
              </template>
              <template #[Categories.Accessories]>
                <AccessoryProducts
                  :tscid="tscid"
                  :scopeId="scopeId"
                  :hardwareOptions="hardwareOptions"
                  :disabled="isBlacklisted || !isAccessoryItemsAllowed"
                  :alert="''"
                  :categories-loading="false"
                  @selected-product="onSelectProduct"
                />
              </template>
              <template #[Categories.Emn]>
                <SubscriptionProductListing
                  :loading="fetching.emn"
                  :tscid="tscid"
                  :nf-agreements="nfAgreements"
                  :subscriptionGroups="emnBundles"
                  :subscriptionDisabled="isBlacklisted || !isSubscriptionItemsAllowed"
                  @selected-subscription="onSelectEmnSubscription"
                />
              </template>
            </Tabs>
          </telia-grid>
        </div>
      </b2b-layout>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { Customer, Organisation, Permissions, Product, SpockBasket } from "../types";
import { storeToRefs } from "pinia";
import { getCustomers } from "../service/ScopeService";
import { emptyBasket, updateAmandaBasket } from "../service/BasketService";
import { useConfigurationStore } from "../store/ConfigurationStore";
import { useStateStore } from "../store/StateStore";
import { useProductStore } from "../store/ProductStore";
import { getCreditCheck, isBlacklistedForFraud } from "../service/ProductService";
import { CreditCheckUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { NfAgreementOnTscidDto } from "@telia/b2b-rest-client/dist/corp-agreements/models/NfAgreementOnTscidDto";
import { TabCategories } from "./TabCategories";
import { TabCategory, isTabCategory } from "./types";
import { useBasketHelper } from "../utils/basketUtil";
import { translate } from "@telia/b2b-i18n";
import { trackProductClick } from "../utils/gaTracking";
import { useGa4Helper } from "../utils/tracking/gaHelper";
import { useFlag } from "@unleash/proxy-client-vue";
import Tabs from "./Tabs.vue";
import HardwareProductListing from "./product-category/hardware/HardwareProductListing.vue";
import AccessoryProducts from "./product-category/accessory/AccessoryProducts.vue";
import ProductAddedConfirmation from "./product-added-confirmation/product-added-confirmation.vue";
import SubscriptionProductListing from "./product-category/subscription/SubscriptionProductListing.vue";
import ProductConfiguratorWrapper from "./product-configurator/ProductConfiguratorWrapper.vue";
import MaxItemsModal from "./MaxItemsModal.vue";
import CustomerSelector from "./scope-selector/CustomerSelector.vue";
import AgreementDownloads from "./AgreementDownloads.vue";
import { FunnelGroup } from "@telia/b2b-ecommerce-tracking/dist/interfaces";

// TODO: Feature flags. Cleanup when appropriate.
const orderSurveyEnabled = useFlag("b2b-mco-order-survey");

const Categories = TabCategories;

interface Props {
  tscid: string;
  organisations: Organisation[];
  scopeId: string;
  permissions: Permissions;
  loadingOrganisations: boolean;
  productCode?: string;
  prefetchErrors: string[];
  nfAgreements: NfAgreementOnTscidDto[];
}
const newSwitchboardEnabled = useFlag("b2b-mco-new-switchboard");

const props = defineProps<Props>();
const stateStore = useStateStore();
const { basket, customerNumber } = storeToRefs(stateStore);
const element = ref<HTMLElement | null>(null);

const configurationStore = useConfigurationStore();
const { selectedProduct } = storeToRefs(configurationStore);
const productStore = useProductStore();
const { subscriptionBundles, hardwareBundles, emnBundles, fetching } = storeToRefs(productStore);
const { addToBasket, isAddingItemToBasket, showConfirmation, addToBasketError } = useBasketHelper(
  newSwitchboardEnabled.value
);
const { trackBasketFail, trackSurveyInitiate } = useGa4Helper();

const customerNumbers = ref<Customer[]>([]);
const loadingCustomerNumbers = ref(false);
const isEmptyingBasket = ref(false);
const spockBasket = ref<SpockBasket>();
const basketOpen = ref(false);
const productAddedToBasket = ref<Product | undefined>();
const showMaxItemsModal = ref(false);
const showSurveyModal = ref(false);

const creditCheck = ref<CreditCheckUI>();
const isBlacklisted = ref(false);
const generalErrors = ref<string[]>([]);
const t = translate;

const showCustomerNumberNotification = computed(() => {
  return !customerNumber.value && !!basket.value;
});

const categoryErrors = computed(() => {
  const errors = productStore.categoryErrors
    .filter((error) => error.category === activeCategory.value)
    .reduce(
      (accumulator, current) => {
        if (!accumulator.find((item) => item.error === current.error)) {
          accumulator.push(current);
        }
        return accumulator;
      },
      [] as { category: TabCategory; error: string }[]
    );

  if (showCreditCheckAlert.value) {
    errors.unshift({ category: activeCategory.value, error: "creditCheck" });
  }

  return errors;
});

const activeCategory = computed<TabCategory>(() => {
  if (isTabCategory(stateStore.category)) {
    return stateStore.category;
  } else {
    return "mobilabonnemang";
  }
});

const customerNumberOptions = computed(() =>
  customerNumbers.value.map((c) => ({
    label: `${c.name} (${c.customerNumber})`,
    value: c.customerNumber,
  }))
);

const hardwareOptions = computed(() => {
  return [
    ...new Set(
      hardwareBundles.value
        .map((hg) => hg.products.map((p) => ({ name: p.name, value: p.productCode })))
        .flat()
        .sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1))
    ),
  ];
});

const numberOfItemsInBasket = computed(() => {
  return (
    (basket.value?.lines?.length || 0) +
    (basket.value?.hardwareLines?.length || 0) +
    (basket.value?.processLines?.length || 0) +
    (basket.value?.accessoryLines?.length || 0) +
    (basket.value?.emnLines?.length || 0) +
    (spockBasket?.value?.broadbandItems?.length || 0)
  );
});

const hasReachedMaxNumberOfItems = computed(() => {
  return basket.value?.maxItemsAllowed
    ? numberOfItemsInBasket.value >= basket.value?.maxItemsAllowed
    : false; // TODO
});

const accessoryProductCodesInBasket = computed(() => {
  if (basket.value?.accessoryLines) {
    return basket.value.accessoryLines
      .map((l) => l.product?.productCode)
      .filter((i) => i !== undefined) as string[];
  }
  return [];
});

const isSubscriptionItemsAllowed = computed(() => {
  if (!basket.value?.lines) {
    return true;
  }
  if (
    creditCheck.value?.subscriptionLimit !== undefined &&
    creditCheck.value?.subscriptionLimit !== null
  ) {
    return basket.value.lines.length < creditCheck.value.subscriptionLimit;
  }
  return true;
});
const isHardwareItemsAllowed = computed(() => {
  if (!basket.value?.hardwareLines) {
    return true;
  }
  if (
    creditCheck.value?.mobileDeviceLimit !== undefined &&
    creditCheck.value?.mobileDeviceLimit !== null
  ) {
    return basket.value.hardwareLines.length < creditCheck.value.mobileDeviceLimit;
  }
  return true;
});

const agreement = computed(() => {
  return stateStore.agreement;
});

const isAccessoryItemsAllowed = computed(() => {
  return creditCheck.value?.status !== "REJECTED";
});

const showCreditCheckAlert = computed(() => {
  switch (activeCategory.value) {
    case TabCategories.Subscription:
      return !isSubscriptionItemsAllowed.value;
    case TabCategories.Hardware:
      return !isHardwareItemsAllowed.value;
    case TabCategories.Accessories:
      return !isAccessoryItemsAllowed.value;
    default:
      return creditCheck.value?.status === "REJECTED";
  }
});

const maxBasketItems = computed(() => {
  return basket.value?.maxItemsAllowed ?? 0;
});

onMounted(async () => {
  updateAmandaBasket(element.value as HTMLElement); // Weird TS  type error when building
  await Promise.allSettled([fetchCustomerNumber(), fetchCreditCheck(), fetchBlackListed()]);
  window.addEventListener("MDigital_Form_Close_No_Submit", () => {
    handleCloseSurvey();
  });
  window.addEventListener("MDigital_ThankYou_Close", () => {
    handleCloseSurvey();
  });
});

function handleBasketError(event: CustomEvent) {
  const fetchError = event.detail[0] as boolean;
  if (fetchError) {
    let funnelGroup: Record<TabCategory, FunnelGroup> = {
      emn: "emn",
      mobilabonnemang: "mobile_subscriptions",
      mobiltelefoner: "mobile_phones",
      tillbehor: "accessories",
    };
    trackBasketFail(funnelGroup[activeCategory.value]);
  }
}

function tabChange(tab: string) {
  stateStore.setCategory(tab);
}

function closeConfiguration() {
  configurationStore.unsetProduct();
  configurationStore.unsetConfiguration();
  showConfirmation.value = false;
  productAddedToBasket.value = undefined;
}

function continueToBasket() {
  closeConfiguration();
  basketOpen.value = true;
}

function handleCloseMaxItemsModal() {
  showMaxItemsModal.value = false;
}

function handleOpenBasket() {
  showMaxItemsModal.value = false;
  handleBasketDrawerState(true);
}

function handleBasketDrawerState(open: boolean) {
  basketOpen.value = open;
}

function handleAddToBasket() {
  if (element.value) {
    addToBasket(element.value as HTMLElement);
  }
}

function showAccessories() {
  closeConfiguration();
  stateStore.setCategory("tillbehor");
}

function onSelectProduct(product: Product) {
  if (isBlacklisted.value || showCreditCheckAlert.value) {
    return;
  }

  if (hasReachedMaxNumberOfItems.value) {
    showMaxItemsModal.value = true;
  } else {
    configurationStore.selectProduct(product);
    trackProductClick(product);
  }
}

function onSelectEmnSubscription(subscription: { offerCode: string; productCode: string }) {
  const emnOffers = emnBundles.value.filter(
    (group) => group.productCode === subscription.offerCode
  );

  if (emnOffers.length) {
    // EMN offers are sharing productCode, look in all emn bundles for the product
    const product = emnOffers
      .map((emnOffer) => emnOffer.products)
      .flat()
      .find((product) => product.productCode === subscription.productCode);
    if (product) {
      onSelectProduct(product);
    }
  }
}

function onSelectSubscription(subscription: { offerCode: string; productCode: string }) {
  const productGroup = subscriptionBundles.value.find(
    (group) => group.productCode === subscription.offerCode
  );
  if (productGroup) {
    const product = productGroup.products.find(
      (product) => product.productCode === subscription.productCode
    );
    if (product) {
      onSelectProduct(product);
    }
  }
}

async function fetchCustomerNumber() {
  loadingCustomerNumbers.value = true;
  try {
    customerNumbers.value = await getCustomers(props.scopeId, props.tscid);
    if (customerNumbers.value.length === 1) {
      stateStore.setCustomerNumber(customerNumbers.value[0].customerNumber);
    }
  } catch (error) {
    //TODO funnel fail - QUESTION
    generalErrors.value.unshift("fetchCustomerNumber");
  }
  loadingCustomerNumbers.value = false;
}

async function fetchCreditCheck() {
  try {
    creditCheck.value = await getCreditCheck(props.scopeId, props.tscid);
    if (creditCheck.value?.status === "MANUAL") {
      generalErrors.value.unshift("manualCreditCheck");
    }
  } catch (error) {
    console.log("error: fetch creditcheck");
  }
}

async function fetchBlackListed() {
  try {
    isBlacklisted.value = await isBlacklistedForFraud(props.scopeId, props.tscid);
    if (isBlacklisted.value) {
      generalErrors.value.unshift("fraudCheck");
    }
  } catch (error) {
    console.log("error: fetch fraudcheck");
  }
}

function handleSelectCustomer(customerNumber: string) {
  if (stateStore.customerNumber && customerNumber !== stateStore.customerNumber) {
    handleEmptyBasket();
  }
  stateStore.setCustomerNumber(customerNumber);
}

function authorizedToOpenFrameworkAgreement() {
  return agreement.value?.owner && props.permissions.hasReadAgreement;
}

async function handleEmptyBasket() {
  if (numberOfItemsInBasket.value > 0) {
    isEmptyingBasket.value = true;
    await emptyBasket(stateStore.scopeId, stateStore.tscid);
    isEmptyingBasket.value = false;
    updateAmandaBasket(element.value as HTMLElement); //Weird TS  type error when building
  }
}

async function handleUpdateBasket() {
  updateAmandaBasket(element.value as HTMLElement);
}

function handleShowSurvey() {
  showSurveyModal.value = true;
  trackSurveyInitiate("click", "Give us feedback");
  // @ts-ignore
  window.KAMPYLE_ONSITE_SDK.updatePageView();
}

function handleCloseSurvey() {
  showSurveyModal.value = false;
}

window.onpopstate = function () {
  if (showConfirmation.value) {
    closeConfiguration();
  }
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/breakpoints/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";

.order-products {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__configure-container {
    height: 100%;
  }

  &__header {
    background-color: $telia-gray-50;
    padding: $telia-spacing-16 0 $telia-spacing-64;
  }

  &__header-content {
    margin-bottom: $telia-spacing-48;
    padding: 0 $telia-spacing-96;
  }

  &__tab-categories {
    margin-top: calc(4.2rem * -1);
  }

  &__messages {
    margin-top: $telia-spacing-24;
    display: flex;
    justify-content: center;
  }

  &__customer-agreement-container {
    display: flex;
  }
}

.survey-button {
  display: flex;
  justify-content: end;

  @media (max-width: $telia-breakpoint-medium) {
    justify-content: start;
    margin-top: $telia-spacing-12;
  }
}
</style>
