import { onUnmounted, ref, Ref } from "vue";
import { UnleashClient } from "@unleash/proxy-client-vue";
import { getConfig } from "@telia/b2b-unleash-client-config";

interface ClientWithScopeIdAndReady {
  client: Ref<UnleashClient | null>;
  flagsReady: Ref<boolean>;
  flagsError: Ref<boolean>;
}

export function useUnleashClientWithScopeId(appName?: string): Ref<UnleashClient | null> {
  const client: Ref<UnleashClient | null> = ref(null);
  (async (): Promise<void> => {
    const config = await getConfig(appName);
    client.value = new UnleashClient(config);
    await client.value.start();
  })();

  return client;
}

export function useUnleashClientWithScopeIdAndFlagStatus(
  appName?: string
): ClientWithScopeIdAndReady {
  const client: Ref<UnleashClient | null> = ref(null);
  const flagsReady: Ref<boolean> = ref(false);
  const flagsError: Ref<boolean> = ref(false);
  (async (): Promise<void> => {
    const config = await getConfig(appName);
    client.value = new UnleashClient(config);
    client.value.on("error", () => {
      flagsError.value = true;
    });
    client.value.on("ready", () => {
      flagsReady.value = true;
    });
    await client.value.start();
  })();

  return { client, flagsReady, flagsError };
}

export async function getUnleashClientWithScopeId(appName?: string): Promise<UnleashClient> {
  const config = await getConfig(appName);
  const client = new UnleashClient(config);

  return client;
}

export function listenForFlagChanges(client: UnleashClient, flag: Ref<boolean>, flagName: string) {
  flag.value = Boolean(client.isEnabled(flagName));
  function onUpdate() {
    const enabled = client.isEnabled(flagName);
    if (enabled !== flag.value) {
      flag.value = !!enabled;
    }
  }

  function onReady() {
    flag.value = Boolean(client.isEnabled(flagName));
  }

  client.on("ready", onReady);
  client.on("update", onUpdate);

  onUnmounted(() => {
    client.off("ready", onReady);
    client.off("update", onUpdate);
  });

  return flag;
}
