<template>
  <div>
    <ConfiguratorRow>
      <b2x-radio-card
        class="option"
        value="new-phone-number"
        :label="t('number.phoneNumberNewTitle')"
        :name="'new-phone-number'"
        :checked="selected === 'new-phone-number'"
        @change="() => setSelected('new-phone-number')"
      >
        <div>
          <telia-p>{{ t("number.phoneNumberNewSubtitle") }}</telia-p>
        </div>
      </b2x-radio-card>
      <b2x-radio-card
        class="option"
        value="keep-phone-number"
        :label="t('number.phoneNumberKeepTitle')"
        :name="'keep-phone-number'"
        :checked="selected === 'keep-phone-number'"
        @change="() => setSelected('keep-phone-number')"
      >
        <div>
          <telia-p>{{ t("number.phoneNumberKeepSubtitle") }}</telia-p>
        </div>
      </b2x-radio-card>
      <b2x-radio-card
        class="option"
        value="new-phone-number-from-series"
        :label="t('number.phoneNumberFromSeriesTitle')"
        :name="'new-number-series'"
        :disabled="numberSeriesOptionDisabled"
        :checked="selected === 'new-phone-number-from-series'"
        @change="() => setSelected('new-phone-number-from-series')"
      >
        <div>
          <telia-p>{{ t("number.phoneNumberFromSeriesSubtitle") }}</telia-p>
        </div>
      </b2x-radio-card>
    </ConfiguratorRow>
    <div class="row">
      <div v-show="selected === 'new-phone-number-from-series'" class="number-config">
        <NewNumberFromSeries
          v-if="!numberSeriesError"
          :scope-id="scopeId"
          :tscid="tscid"
          @change="updateNewFromSeriesMsisdn"
          @new-booking-id="setBookingId"
          @empty-number-series="onEmptyNumberSeries"
          @number-series-error="onErrorNumberSeries"
        />
        <telia-notification
          v-else
          heading-tag="h3"
          status="warning"
          t-id="number-series-alert"
          class="tc-margin__top--none"
        >
          <span slot="heading">{{ t("number.defaultAlertHeader") }}</span>
          <span slot="content">
            <telia-p>
              {{ t("number.numberSeriesError") }}
            </telia-p>
          </span>
        </telia-notification>
      </div>
      <div v-if="selected === 'new-phone-number'" class="number-config">
        <NewNumber
          t-id="new-number"
          :selected-msisdn="msisdn['new-phone-number']"
          :scope-id="scopeId"
          :tscid="tscid"
          @change="updateNewMsisdn"
          @new-booking-id="setBookingIdCustomEvent"
        />
      </div>
      <div v-if="selected === 'keep-phone-number'" class="number-config">
        <MessagePresenter v-if="numberMigrationError" message-type="numberMigration" />
        <b2b-number-migration-configuration
          v-else
          t-id="phone-number__keep-number-configuration"
          :tscid="tscid"
          @number-migration-configuration-change="
            setB2bNumberMigrationConfiguration($event.detail[0])
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translate } from "@telia/b2b-i18n";
import { computed, ref, watch } from "vue";
import NewNumberFromSeries from "./NewNumberFromSeries.vue";
import NewNumber from "./NewNumber.vue";
import ConfiguratorRow from "../../../../shared/ConfiguratorRow.vue";
import MessagePresenter from "../../../../MessagePresenter.vue";
import { NumberMigrationConfiguration, SelectableType, PhoneNumber, MsisdnTypes } from "./types";
import { NumberMigrationProduct } from "../../../../../types";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";
import { useSubscriptionConfigurationStore } from "../../../../../store/SubscriptionConfigurationStore";
import moment from "moment";

interface Props {
  scopeId: string;
  tscid: string;
  migrationProducts?: NumberMigrationProduct[];
  numberMigrationError?: boolean;
}

const emit = defineEmits<{
  (e: "valid", valid: boolean): void;
  (e: "change", currentValue: PhoneNumber): void;
  (e: "number-migration-configuration", configuration: NumberMigrationConfiguration): void;
}>();

const t = translate;

const subscriptionConfigStore = useSubscriptionConfigurationStore();

const props = withDefaults(defineProps<Props>(), {
  migrationProducts: () => [],
  numberMigrationError: false,
});
const msisdn = ref<MsisdnTypes>({
  "keep-phone-number": "",
  "new-phone-number": "",
  "new-phone-number-from-series": "",
});
const bookingId = ref<MsisdnTypes>({});
const numberSeriesError = ref(false);
const emptyNumberSeries = ref(false);
const numberMigrationConfiguration = ref<NumberMigrationConfiguration | undefined>();
const selected = ref<SelectableType>("new-phone-number");

const currentValue = computed(() => {
  if (!selected.value) {
    return undefined;
  }
  const currentMsisdn = msisdn.value[selected.value];
  if (!currentMsisdn) {
    return undefined;
  }
  return {
    component: selected.value,
    msisdn: currentMsisdn,
    formattedMsisdn: formatPhoneNumber(currentMsisdn),
    bookingId: bookingId.value[selected.value],
  };
});

const valid = computed(() => {
  return (
    !!currentValue?.value?.msisdn &&
    (selected.value === "keep-phone-number" ? !!numberMigrationConfiguration.value : true)
  );
});
const numberSeriesOptionDisabled = computed(() => {
  if (emptyNumberSeries.value) {
    return !numberSeriesError.value;
  } else {
    return false;
  }
});

function updateNewMsisdn(selectedMsisdn: string) {
  msisdn.value["new-phone-number"] = selectedMsisdn;
}
function updateNewFromSeriesMsisdn(selectedMsisdn: string) {
  msisdn.value["new-phone-number-from-series"] = selectedMsisdn;
}
function emitPhoneNumber() {
  if (currentValue.value) {
    emit("change", currentValue.value);
  }
}
function emitNumberMigrationConfiguration() {
  if (numberMigrationConfiguration.value) {
    emit("number-migration-configuration", numberMigrationConfiguration.value);
  }
}
function emitValidity(valid: boolean) {
  emit("valid", valid);
}
function setBookingId(bookingIdParam: string) {
  if (selected.value) {
    bookingId.value[selected.value] = bookingIdParam;
  }
}
function setBookingIdCustomEvent(bookingIdParam: string) {
  if (selected.value) {
    bookingId.value[selected.value] = bookingIdParam;
  }
}
function setB2bNumberMigrationConfiguration(
  numberMigrationConfigurationParam: NumberMigrationConfiguration | undefined
) {
  if (!numberMigrationConfigurationParam) {
    resetNumberMigrationConfiguration();
    return;
  }

  msisdn.value["keep-phone-number"] = numberMigrationConfigurationParam.msisdn;
  numberMigrationConfiguration.value = {
    ...numberMigrationConfigurationParam,
    migrationProduct: getNumberMigrationProduct(numberMigrationConfigurationParam.type),
  };
  emitNumberMigrationConfiguration();
}
function getNumberMigrationProduct(type: string) {
  return props.migrationProducts.find(
    ({ subCategory }) => subCategory === getCategoryFromType(type)
  ) as NumberMigrationProduct;
}
function getCategoryFromType(type: string) {
  return type === "TRANSFER" ? "transferofownership" : "portin";
}
function resetNumberMigrationConfiguration() {
  numberMigrationConfiguration.value = undefined;
  msisdn.value["keep-phone-number"] = undefined;
}
function setSelected(selectedParam: string) {
  selected.value = selectedParam as SelectableType;
  emitPhoneNumber();
  emitNumberMigrationConfiguration();
}
function onEmptyNumberSeries() {
  emptyNumberSeries.value = true;
}
function onErrorNumberSeries() {
  numberSeriesError.value = true;
}
/*
function handleTrackingData(data: { type: any; origin: any }) {
  const product = getNumberMigrationProduct(data.type);

  if (product) {
    // TODO
    //trackProductDetail(product, data.origin);
  }
}
*/

watch(selected, () => {
  subscriptionConfigStore.resetPhoneMigration();
});

watch(
  msisdn,
  () => {
    emitPhoneNumber();
  },
  { deep: true }
);

watch(valid, () => {
  emitValidity(valid.value);
});
</script>
<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.row {
  margin-bottom: $telia-spacing-24;
}
.option {
  flex: 1 1 30%;
}

.number-config {
  width: 100%;
}
</style>
