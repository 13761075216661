import Big from "big.js";
import {
  AccessoryProduct,
  HardwareProduct,
  HardwareVariant,
  NumberMigrationProduct,
  Product,
  SubscriptionBundle,
  SubscriptionProduct,
  isHardwareProduct,
  isSubscriptionProduct,
} from "../types";

import {
  writeProductData,
  trackEvent,
  category,
  action,
  getConcatenatedCategories,
  customDimensions,
  label,
} from "@telia/b2b-web-analytics-wrapper";

import { getGAProductListName } from "./productCategory";
import { AddonV2 } from "../components/product-configurator/subscription-configurator/components/addon/types";
import {
  DatasimPayload,
  EsimWatchPayload,
} from "../components/product-configurator/subscription-configurator/types";
import {
  SwitchboardAddon,
  SwitchboardConfiguration,
} from "../components/product-configurator/subscription-configurator/components/subscription/switchboard/switchboard-types";
import { Fees } from "../components/product-configurator/hardware-configurator/types";
import { useHardwareStore } from "../store/HardwareConfigurationStore";
import { useSubscriptionConfigurationStore } from "../store/SubscriptionConfigurationStore";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";

function getVariantWithLowestPrice(variants: HardwareVariant[]) {
  return variants.reduce((previous, current) =>
    previous.unitPrice < current.unitPrice ? previous : current
  );
}

function buildPayload(product: Product, pa: "detail" | "click") {
  const payload = writeProductData(defaultPayload(pa), getPayload(product), 1, 1);
  return payload;
}

interface PayloadProduct {
  id: string;
  name: string;
  category: string;
  subCategory?: string;
  brand: string;
  productCode: string;
  totalUnitFee: string;
}

function getPayload(product: Product): PayloadProduct {
  if (isHardwareProduct(product)) {
    const cheapestVariant = getVariantWithLowestPrice(product.variants);
    return {
      ...product,
      id: cheapestVariant.id,
      totalUnitFee: cheapestVariant.unitPrice,
    };
  } else if (isSubscriptionProduct(product)) {
    return {
      ...product,
      subCategory: product.subcategory,
      brand: "",
      totalUnitFee: new Big(product?.oneTimeFee).plus(product?.recurringFee).toString(),
    };
  } else {
    return {
      ...product,
      totalUnitFee: product.unitPrice,
    };
  }
}

export function trackProductConfiguration(selectedProduct: Product) {
  const payload: Record<string, string> = buildPayload(selectedProduct, "detail");
  trackEvent(
    category.SALESFLOW,
    action.PRODUCT_DETAIL,
    selectedProduct.name,
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

interface TrackingProduct {
  id?: string;
  category: string;
  subCategory?: string;
  subcategory?: string;
  oneTimeFee?: string;
  recurringFee?: string;
  addons?: AddonV2[] | SwitchboardAddon[];
  numberOfHuntingGroups?: string;
  productCode: string;
  name: string;
}

export function trackAddSubscriptionToBasket(
  product: SubscriptionProduct,
  selectedAddons: AddonV2[],
  selectedExtraDevices: Array<DatasimPayload | EsimWatchPayload>,
  numberMigrationProduct: NumberMigrationProduct | undefined,
  selectedSwitchboardUser: SwitchboardConfiguration | undefined
) {
  let payload = defaultPayload("add");

  const products: TrackingProduct[] = [product, ...selectedAddons, ...selectedExtraDevices];

  if (numberMigrationProduct) {
    numberMigrationProduct.category = "mobilevoicesubscription";
    products.push(numberMigrationProduct);
  }

  if (selectedSwitchboardUser) {
    products.push(selectedSwitchboardUser, ...(selectedSwitchboardUser.addons || []));
    const tpUserIndex = products.findIndex((p) =>
      isSwitchBoardUser(p.category, p.subCategory || p.subcategory)
    );
    if (tpUserIndex) {
      products[tpUserIndex].numberOfHuntingGroups = getHuntingGroups(
        selectedSwitchboardUser.huntingGroups
      );
    }
  }

  products.forEach((product, i) => {
    const totalUnitFee = new Big(product.oneTimeFee || 0).plus(product.recurringFee || 0);
    payload = writeProductData(
      payload,
      { ...product, id: product.id ?? "", brand: "", totalUnitFee: totalUnitFee.toString() },
      i + 1,
      1
    );

    if (isSwitchBoardUser(product.category, product.subCategory || product.subcategory)) {
      payload[`pr${i + 1}cd7`] = product.numberOfHuntingGroups ?? "";
    }
  });

  trackEvent(
    category.SALESFLOW,
    action.PRODUCT_ADD_TO_CART,
    product.name,
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key] as string,
    }))
  );
}

export function trackAddHardware(product: HardwareProduct) {
  const hardwareConfigurationStore = useHardwareStore();
  const subscriptionConfigurationStore = useSubscriptionConfigurationStore();
  const { deviceFees, isUpfrontPayment, hardwareVariant, hardwareSubscription } =
    hardwareConfigurationStore;
  const subscriptionConfiguration = subscriptionConfigurationStore.getConfiguration;

  let payload = defaultPayload("add");
  const commitment = subscriptionConfiguration.commitment || 0;
  const oneTimeFee = hardwareOneTimeFee(
    deviceFees.hardware,
    isUpfrontPayment ? "UPFRONT" : "MONTHLY"
  );
  const recurringFee = hardwareRecurringFee(
    deviceFees.hardware,
    isUpfrontPayment ? "UPFRONT" : "MONTHLY"
  );

  const leastTotalCost = hardwareLeastTotalCost(oneTimeFee, recurringFee, commitment);
  const variant = hardwareVariant;
  if (variant) {
    payload = writeProductData(
      payload,
      { ...variant, totalUnitFee: leastTotalCost.toString() },
      1,
      1
    );
    payload.pr1nm = product.name;
    payload.pr1cm13 = +recurringFee + ""; // Installments (påslag månadskostnad)
    payload.pr1cm14 = +oneTimeFee + ""; // Engångskostnad upfront

    if (hardwareConfigurationStore.getConfiguration.subscription) {
      payload.pr1cd12 = hardwareSubscription.operation === "EXTEND_SUBSCRIPTION" ? "extend" : "new"; // Contract_Type
      payload.pr1cd52 = `${commitment} months`; // CommitmentTime_Product
      payload.pr1cc = "COMBINED_HARDWARE_SUBSCRIPTION_OFFER";

      const subscriptionProduct =
        hardwareConfigurationStore.getConfiguration.subscription.subscriptionProduct;
      if (subscriptionProduct && hardwareSubscription.operation !== "CHANGE_SUBSCRIPTION_IN_CART") {
        const products: Array<TrackingProduct> = [
          subscriptionProduct as SubscriptionProduct,
          ...subscriptionConfiguration.addonsV2,
          ...subscriptionConfiguration.datasims,
          ...subscriptionConfiguration.esimWatches,
        ];

        const numberMigrationProduct = subscriptionConfiguration.numberMigration?.migrationProduct;

        if (numberMigrationProduct) {
          numberMigrationProduct.category = "mobilevoicesubscription";
          products.push(numberMigrationProduct);
        }
        const selectedSwitchboardUser = subscriptionConfiguration.switchboardUser;
        if (selectedSwitchboardUser) {
          products.push(selectedSwitchboardUser, ...(selectedSwitchboardUser.addons || []));
          const tpUserIndex = products.findIndex((p) => {
            return isSwitchBoardUser(p.category, p.subCategory || p.subcategory);
          });
          if (tpUserIndex) {
            products[tpUserIndex].numberOfHuntingGroups = getHuntingGroups(
              selectedSwitchboardUser.huntingGroups
            );
          }
        }

        products.forEach((product, i) => {
          payload = buildProductPayload(product, payload, i + 2, {
            product: subscriptionProduct,
            commitment: subscriptionConfiguration.commitment,
            extended: hardwareSubscription.operation === "EXTEND_SUBSCRIPTION",
          });
          if (isSwitchBoardUser(product.category, product.subCategory || product.subcategory)) {
            payload[`pr${i + 2}cd7`] = product.numberOfHuntingGroups;
          }
        });
      }
    }
    trackEvent(
      category.SALESFLOW,
      action.PRODUCT_ADD_TO_CART,
      variant.name,
      "0",
      Object.keys(payload).map((key) => ({
        type: key,
        value: payload[key] as string, // Done to keep the data the same as before
      }))
    );

    if (
      hardwareSubscription.operation === "CHANGE_SUBSCRIPTION_IN_CART" &&
      hardwareConfigurationStore.selectedSubscription
    ) {
      let payload2 = defaultPayload("add");
      payload2 = buildProductPayload(hardwareConfigurationStore.selectedSubscription, payload2, 1, {
        product: hardwareConfigurationStore.selectedSubscription,
        commitment,
        extended: false,
      });
      trackEvent(
        category.SALESFLOW,
        action.PRODUCT_CHANGE_IN_CART,
        label.SUBSCRIPTION_ALREADY_IN_CART,
        "0",
        Object.keys(payload2).map((key) => ({
          type: key,
          value: payload2[key] as string,
        }))
      );
    }
  }
}

export function trackAddAccessoryToBasket(accessory: AccessoryProduct) {
  const payload = writeProductData(
    defaultPayload("add"),
    { ...accessory, totalUnitFee: accessory.unitPrice },
    1,
    1
  );
  payload.pr1nm = accessory.name;
  payload.pr1cm13 = 0; // Installments (påslag månadskostnad)
  payload.pr1cm14 = +accessory.unitPrice; // Engångskostnad upfront

  trackEvent(
    category.SALESFLOW,
    action.PRODUCT_ADD_TO_CART,
    accessory.name,
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}
interface Subscription {
  product: SubscriptionProduct;
  extended: boolean;
  commitment: number;
}
function buildProductPayload(
  product: TrackingProduct,
  payload: Record<string, string | number | undefined>,
  i: number,
  subscription: Subscription
) {
  const totalRecurringFee = isSubscription(product, subscription)
    ? new Big((product as SubscriptionProduct).recurringFee).times(subscription.commitment || 1)
    : new Big(product?.recurringFee || 0);
  const totalFee = new Big(product.oneTimeFee || 0).plus(totalRecurringFee);

  payload = writeProductData(
    payload,
    {
      ...product,
      subCategory: product.subcategory ?? product.subCategory,
      id: product.id ?? "",
      totalUnitFee: totalFee.toString(),
    },
    i,
    1
  );

  payload[`pr${i}cm13`] = product.recurringFee; // Installments (påslag månadskostnad)
  payload[`pr${i}cm14`] = product.oneTimeFee; // Engångskostnad upfront

  if (isSubscription(product, subscription)) {
    payload[`pr${i}cc`] = "COMBINED_HARDWARE_SUBSCRIPTION_OFFER";
    payload[`pr${i}cd12`] = subscription.extended ? "extend" : "new"; // Contract_Type
    payload[`pr${i}cd52`] = `${subscription.commitment} months`; // CommitmentTime_Product

    payload[`pr${i}va`] += `-${subscription.commitment}_MONTHS`;

    if (subscription.extended) {
      payload[`pr${i}va`] += "-EXTEND_COMMITMENT";
    }
  }
  return payload;
}

export function trackProductClick(product: Product) {
  const payload = buildPayload(product, "click");
  payload.pal = getGAProductListName(product.category, (product as AccessoryProduct).subCategory);

  trackEvent(
    category.SALESFLOW,
    action.PRODUCT_CLICK,
    product.name,
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

export function trackProductList(productGroups: any[], selectedHwBrandFilter: string) {
  const listName = `Mobile Devices${selectedHwBrandFilter ? " - " + selectedHwBrandFilter : ""}`;
  const payload: any = {
    il1nm: listName, // name of product list
  };
  let index = 1;
  productGroups.forEach((productGroup) => {
    productGroup?.products?.forEach((product: any) => {
      const cheapestVariant = getVariantWithLowestPrice(product.variants);

      payload[`il1pi${index}id`] = cheapestVariant.id;
      payload[`il1pi${index}nm`] = product.name;
      payload[`il1pi${index}ca`] = getConcatenatedCategories(product.category, product.subCategory);
      payload[`il1pi${index}br`] = product.brand;
      payload[`il1pi${index}pr`] = cheapestVariant.unitPrice;
      payload[`il1pi${index}ps`] = index;

      index++;
    });
  });

  // check if there are products in the payload.
  if (index > 1) {
    trackEvent(
      category.SALESFLOW,
      action.PRODUCT_LIST,
      listName,
      "0",
      Object.keys(payload).map((key) => ({
        type: key,
        value: payload[key],
      }))
    );
  }
}

export function trackAccessoryProductList(
  accessories: AccessoryProduct[],
  selectedAccessoryCategory: string
) {
  const listName = `Accessories${
    selectedAccessoryCategory ? " - " + selectedAccessoryCategory : ""
  }`;
  const payload: any = {
    il1nm: listName, // name of product list
  };
  let index = 1;
  accessories?.forEach((product) => {
    Object.assign(
      payload,
      buildListPayload(
        product.id,
        product.name,
        product.category,
        product.subCategory,
        product.brand,
        product.unitPrice,
        index
      )
    );
    index++;
  });

  // check if there are products in the payload.
  if (index > 1) {
    trackEvent(
      category.SALESFLOW,
      action.PRODUCT_LIST,
      listName,
      "0",
      Object.keys(payload).map((key) => ({
        type: key,
        value: payload[key],
      }))
    );
  }
}

export function trackSubscriptionProductList(productGroups: SubscriptionBundle[]) {
  const payload: any = {
    il1nm: "Mobile Subscriptions", // name of product list
  };
  let index = 1;
  productGroups.forEach((productGroup) => {
    productGroup?.products?.forEach((product) => {
      payload[`il1pi${index}id`] = product.id;
      payload[`il1pi${index}nm`] = product.name;
      payload[`il1pi${index}ca`] = product.category;
      payload[`il1pi${index}br`] = "";
      payload[`il1pi${index}pr`] = +product.oneTimeFee + +product.recurringFee;
      payload[`il1pi${index}ps`] = index;

      index++;
    });
  });

  // check if there are products in the payload.
  if (index > 1) {
    trackEvent(
      category.SALESFLOW,
      action.PRODUCT_LIST,
      "order-products",
      "0",
      Object.keys(payload).map((key) => ({
        type: key,
        value: payload[key],
      }))
    );
  }
}

export function trackStartOfSalesFlow(agreementType: AgreementType) {
  trackEvent(category.SALESFLOW, action.INITIATED, "order-products", "0", [
    {
      type: customDimensions.AGREEMENT_TYPE,
      value: agreementType,
    },
    {
      type: customDimensions.IMPERSONATION,
      value: "End Customer",
    },
  ]);
}

function buildListPayload(
  id: string,
  name: string,
  category: string,
  subCategory: string,
  brand: string,
  unitPrice: string,
  index: number
) {
  const payload: any = {};
  payload[`il1pi${index}id`] = id;
  payload[`il1pi${index}nm`] = name;
  payload[`il1pi${index}ca`] = getConcatenatedCategories(category, subCategory);
  payload[`il1pi${index}br`] = brand;
  payload[`il1pi${index}pr`] = unitPrice;
  payload[`il1pi${index}ps`] = index;
  return payload;
}

function defaultPayload(pa: string): Record<string, string | number | undefined> {
  return {
    pa: pa, // enhanced ecommerce action type (:checked used to examplify adding/removing)
    ta: "MyBusiness-Salesflow", // affiliation
    cu: "SEK", // currency
    cd4: "MCO", // teliaAnalytics requires cd4 'ProductOffer', look into this
    cd9: "MCO", // teliaAnalytics requires cd9 'Hierarchy ID', look into this
  };
}

function isSubscription(product: TrackingProduct, subscription: Subscription) {
  return product.id === subscription?.product.id;
}

function hardwareOneTimeFee(fees: Fees["hardware"], paymentVariant: "MONTHLY" | "UPFRONT") {
  if (paymentVariant === "UPFRONT") {
    return fees.upfrontPaymentFee;
  } else if (paymentVariant === "MONTHLY") {
    return fees.oneTimeFee;
  } else {
    return fees.unitPrice || 0;
  }
}

function hardwareRecurringFee(fees: Fees["hardware"], paymentVariant: "MONTHLY" | "UPFRONT") {
  if (paymentVariant === "MONTHLY") {
    return fees.recurringFee;
  }
  return 0;
}

function hardwareLeastTotalCost(
  oneTimeFee: string | number,
  recurringFee: string | number,
  commitment: number
) {
  return new Big(oneTimeFee).plus(new Big(recurringFee).times(commitment || 0));
}

function getHuntingGroups(huntingGroups: { groupNumber: string }[]) {
  return "Number of hunting groups: " + (huntingGroups?.length | 0);
}

function isSwitchBoardUser(category: string, subCategory: string | undefined) {
  return (
    category === "switchboardextension" && (subCategory === "ucuser" || subCategory === "scuser")
  );
}
